.container {
	flex: 1;
	display: flex;
	flex-direction: column;
}
.guess {
	flex: 65;
	display: flex;
	flex-direction: column;
}

.result {
	flex: 35;
	display: flex;
	flex-direction: column;
}
