.container {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1em;
}

.box {
	flex: 1;
	border-radius: 5px;
	border: 1px solid #e9e9e9;
	max-width: 900px;
	display: flex;
	align-items: stretch;
	background: white;
}

.text {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: black;
	padding: 1em;
	text-align: center;
}

.meme {
	padding: 0.5em;
	flex: 1;
	img {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}
