.container {
	flex: 1;

	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;

	font-size: large;
	padding: 0.5em;
	overflow: scroll;

	* {
		max-width: 900px;
	}
}
