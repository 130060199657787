* {
	box-sizing: border-box;
}

body {
	background: radial-gradient(lightgray, whitesmoke);
	background-repeat: no-repeat;
}

.app {
	display: flex;
	flex-flow: column;
	width: 100vw;
}

.content {
	flex: 1;

	display: flex;
	flex-flow: column;
}
