.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}

.cardholder {
	padding: 2em;
	flex-basis: 0;
	flex-grow: 1;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card {
	flex-grow: 1;
	border-radius: 5px;
	border: 1px solid #e9e9e9;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	max-width: 900px;
	width: 100%;
	height: 100%;
	background: white;
	img {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}

.buttonholder {
	flex: 0 0 4em;
}
